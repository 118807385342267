<template>
  <section id="check">
    <div class="title_info">
      <p>
        {{ ccName }}
      </p>
    </div>
    <!-- <div class="step step2">
      <li class="active"><span>1</span>본인확인</li>
      <li><span>2</span>정보등록 및 동의</li>
    </div> -->
    <div class="join_wrap">
      <p class="stitle">
        {{ $t("certificationMessage.enterCertificationCode", [codeDigit]) }}
      </p>
      <div class="resend">
        {{ phoneNumber }}
        <a
          v-if="!firstSended"
          class="btn_resend"
          @click="sendSMSCertificationCode"
        >
          {{ $t("certification.sendSMS") }}
        </a>
        <a
          v-if="firstSended"
          class="btn_resend"
          @click="resendSMSCertificationCode"
        >
          {{ $t("certification.resendSMS") }}
        </a>
      </div>
      <div class="number">
        <ul>
          <li
            v-for="(item, index) in code"
            :key="index"
            :style="{
              width: `calc((100% - 90px) / ${codeDigit})`,
            }"
          >
            <input
              ref="code"
              type="number"
              :value="item"
              :class="[codeActiveIndex === index && 'active']"
              :disabled="!firstSended"
              @focusin="onFocusInCode(index)"
              @focusout="onFocusOutCode"
              @input="onInputCode($event, index)"
              @keydown.delete="onKeyDownBackspaceCode($event, index)"
              @keydown.enter="onKeyDownEnterCode($event, index)"
              @change="onChangeCode"
            />
          </li>
        </ul>
        <p :style="{ color: !certificationTime ? 'red' : null }">
          {{ certificationTimeLabel }}
        </p>
      </div>
    </div>
    <div class="ft_btn">
      <a
        class="btn_point"
        :disabled="!firstSended"
        @click="onClickCertificationConfirm"
      >
        {{ $t("certification.confirm") }}
      </a>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProjectService from "@/service/ProjectService";
import ProjectAPIError from "@/api/error/ProjectAPIError";

export default {
  name: "Certification",
  data() {
    return {
      firstSended: false,
      enc: null,
      redirect: null,
      phoneNumber: null,
      crtfcId: null,
      code: [],
      codeDigit: 4, // TODO : 코드 자리수 임시 하드코딩
      timeLimit: 5, // TODO : 인증번호 제한 시간 임시 하드코딩 (Minute)
      codeActiveIndex: null,
      certificationTime: null, // Second (timeLimit * 60)
      certificationTimer: null,
    };
  },
  async created() {
    const { enc, redirect, phone } = this.$route.query;
    this.enc = enc;
    this.redirect = redirect;
    this.phoneNumber = phone;
    this.clearCode();
  },
  computed: {
    ...mapGetters(["ccName"]),
    certificationTimeLabel() {
      if (this.certificationTime === null)
        return this.$t("certification.timeFirst");
      else {
        const min = Math.floor(this.certificationTime / 60);
        const sec = Math.floor(this.certificationTime % 60);
        if (this.certificationTime > 0) {
          if (min > 0 && sec === 0)
            return this.$t("certification.timeLimitOnlyMinute", [min]);
          else if (min === 0 && sec > 0)
            return this.$t("certification.timeLimitOnlySecond", [sec]);
          else return this.$t("certification.timeLimit", [min, sec]);
        } else {
          return this.$t("certification.timeOut");
        }
      }
    },
  },
  methods: {
    ...mapActions(["setGoCheckIn"]),
    onFocusInCode(index) {
      this.changeCodeActiveIndex(index);
    },
    onFocusOutCode() {
      this.changeCodeActiveIndex();
    },
    async onInputCode(event, index) {
      const { data, inputType } = event;
      this.code[index] = null;
      this.$forceUpdate();
      this.codeActiveIndex = index;
      if (inputType !== "insertText" || /[^0-9]/gim.test(data)) return;
      if (data.length === this.codeDigit) {
        await this.pasteCode(data);
        return;
      }
      this.code[index] = data;
      this.$forceUpdate();
      await this.focusNextCode(index);
    },
    async onKeyDownBackspaceCode(event, index) {
      await this.deleteCode(index);
    },
    async onKeyDownEnterCode(event, index) {
      index + 1 >= this.codeDigit
        ? await this.onClickCertificationConfirm()
        : await this.focusNextCode(index);
    },
    onChangeCode() {
      this.changeCodeActiveIndex();
    },
    async onClickCertificationConfirm() {
      if (!this.certificationTime) {
        await this.confirm({
          content: this.$t("certificationConfirmMessage.isResendSMS"),
          cancelLabel: this.$t("common.no"),
          submitLabel: this.$t("common.yes"),
          submitCallback: async () => await this.resendSMSCertificationCode(),
        });
        return;
      }
      const pureCode = this.code
        // eslint-disable-next-line valid-typeof
        ?.filter((item) => typeof item !== "Number")
        ?.join("");
      if (pureCode.length < this.codeDigit) {
        this.clearCode();
        await this.confirm({
          content: this.$t("certificationMessage.enterCertificationCode", [
            this.codeDigit,
          ]),
          submitCallback: () => this.$refs.code[0].focus(),
        });
        return;
      }
      try {
        await ProjectService.signIn(
          { enc: this.enc, certificationCode: pureCode, crtfcId: this.crtfcId },
          true
        );
      } catch (e) {
        if (e instanceof ProjectAPIError) {
          const { status } = e.data;
          switch (status) {
            case "NO_CRTFC_NO":
              this.clearCode();
              break;
          }
          throw new Error(e);
        }
      }
      this.clearCertificationTimer();
      await this.setGoCheckIn(true);
      await this.$router.push(this.redirect);
    },
    async focusNextCode(index) {
      index < this.codeDigit - 1
        ? await this.focusCodeIndex(index + 1)
        : await this.onClickCertificationConfirm();
    },
    async focusCodeIndex(index = 0) {
      await this.$nextTick();
      this.$refs.code[index].focus();
    },
    changeCodeActiveIndex(index = null) {
      this.codeActiveIndex = index;
      this.$forceUpdate();
    },
    async pasteCode(code) {
      for (let i = 0; i < this.codeDigit; i++) this.code[i] = code[i];
      this.$forceUpdate();
      await this.onClickCertificationConfirm();
    },
    async deleteCode(index) {
      this.code[index] = null;
      this.$forceUpdate();
      await this.$nextTick();
      if (index > 0) await this.focusCodeIndex(index - 1);
    },
    clearCode() {
      this.code = Array(this.codeDigit).fill(null);
    },
    async sendSMSCertificationCode() {
      const { data: crtfcId } =
        await ProjectService.postEcoFrontSendCertification(
          this.enc,
          this.phoneNumber.replace(/[^0-9]/gim, "")
        );
      this.crtfcId = crtfcId;
      this.firstSended = true;

      await this.$nextTick();

      this.runCertificationTimer();
      await this.focusCodeIndex();
    },
    async resendSMSCertificationCode() {
      const { data: crtfcId } =
        await ProjectService.postEcoFrontResendCertification(
          this.enc,
          this.crtfcId
        );
      this.crtfcId = crtfcId;
      this.clearCode();
      this.runCertificationTimer();
      await this.focusCodeIndex();
    },
    runCertificationTimer() {
      this.clearCertificationTimer();
      this.certificationTimer = setInterval(() => {
        if (--this.certificationTime === 0) this.clearCertificationTimer(true);
      }, 1000);
    },
    clearCertificationTimer(isNotTimeClear = false) {
      if (!isNotTimeClear) this.certificationTime = this.timeLimit * 60;
      clearInterval(this.certificationTimer);
      this.certificationTimer = null;
    },
  },
};
</script>
